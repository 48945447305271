<template>
  <div class="login-container">
    <b-row>
      <b-col md="12">
        <div class="basic-1 title-1-bold-24 d-flex"
             style="justify-content: center; align-items: center; margin-bottom: 40px">
          {{ $t("AUTH.LOGIN_TO_EDNEOS") }}
        </div>
      </b-col>
      <b-col md="6" class="mb-5 first-col">

        <div class="hello">
          <b-row>
            <GoogleLogin
                class="btn btn-light login-with-google w-100"
                :params="{client_id: '528432422488-2f03lscvhq6j3d7anmsnh7tq4vtu0il5',
                 scope: 'profile email',
                plugin_name:'Edneos'}"
                :onSuccess="loginWithGoogle"
                :onFailure="onFailure">
              <img src="/media/google.svg" alt="">
              {{ $t('AUTH.LOGIN_WITH_GOOGLE') }}</GoogleLogin>
          </b-row>
        </div>
        <div class="create-account">
          <div class="basic-1" style="font-size: 12px; margin-bottom: 12px">
            {{ $t("AUTH.DONT_HAVE_ACCOUNT") }}
          </div>
          <router-link
            :to="{ path: '/auth/create-account' }"
            class="btn btn-primary"
            style="width: 100%"
          >
            {{ $t("AUTH.CREATE_ACCOUNT") }}
          </router-link>
        </div>
      </b-col>
      <b-col md="6" class="mb-5">
        <form
          class="form sh-auth-form"
          novalidate="novalidate"
          id="sh_login_form"
        >
          <div v-if="loginError" class="login-error-label d-flex justify-content-end">
            {{ $t("AUTH.LOGIN_ERROR") }}
          </div>
          <div class="form-floating mb-4">
            <input type="text"
                   :class="'form-control ' + (loginError ? 'login-error' : '')"
                   v-model="model[fields.email.name]"
                   :id="fields.email.name"
                   :placeholder="fields.email.label">
            <label for="floatingInput">{{ fields.email.label }}</label>
          </div>
          <div class="form-floating mb-4" style="position: relative" :key="showPassword">
            <input v-if="showPassword" type="text"
                   class="form-control"
                   v-model="model[fields.password.name]"
                   :id="fields.password.name"
                   :placeholder="fields.password.label"
                   v-b-visible="true"
            >
            <input v-else type="password"
                   class="form-control"
                   v-model="model[fields.password.name]"
                   :id="fields.password.name"
                   :placeholder="fields.password.label"
                   v-b-visible="true"
            >
            <label for="floatingPassword">{{ fields.password.label }}</label>

            <div class="control">
              <unicon @click="() => showPassword = !showPassword"
                      :name="showPassword ? 'eye-slash' : 'eye'" fill="#9A9FA7"/>

            </div>
          </div>
          <div class="auth-forgot-password mb-5 basic-1">

            {{ $t("AUTH.FORGOT") }}
            <router-link
              :to="{ path: '/auth/forgot-password' }"
              class="text-primary font-size-sm font-weight-bold text-hover-primary default-color"
            >
              {{ $t("AUTH.PASSWORD") }}
            </router-link>
            ?
          </div>
          <div class="pb-lg-0 mb-4">
            <base-button
              variant="secondary"
              block
              :callback="doSubmit" :callbackParams="1">{{ $t('AUTH.LOGIN_BUTTON') }}
            </base-button>
          </div>
        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserModel from '@/modules/auth/user-model';
import { server } from '@/const';
import { destroyToken, saveToken } from '@/shared/services/jwt.service';
import GoogleLogin from 'vue-google-login';
import i18n from '@/shared/plugins/vue-i18n';
import BaseButton from '../../../shared/components/BaseButton.vue';
import Notify from '../../../shared/utils/notify';

const { fields } = UserModel;

export default {
  name: 'LoginPage',
  components: { BaseButton, GoogleLogin },
  data() {
    return {
      componentKey: 0,
      fields,
      model: {},
      showPassword: false,
      loginError: false,
      isInitLogin: false,
      isSignIn: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    async onFailure() {
      Notify('warning', i18n.t('ERRORS.DEFAULT_ERROR_MESSAGE'));
    },
    loginWithGoogle(googleResponse) {
      console.log(googleResponse);
      const loginPath = '/WebLogin.php';
      const data = new FormData();
      data.append('token', googleResponse.Qc.id_token);
      data.append('provider', 'google');
      /*      data.append('login', googleResponse.xy.oz);
      data.append('name', googleResponse.xy.hg);    */
      data.append('type', 'mentor');
      fetch(server + loginPath, {
        method: 'POST',
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          destroyToken();
        }
        if (json.status === 'ok') {
          this.loginError = false;
          saveToken(json.token);
          window.localStorage.setItem('role', json.type);
          this.$router.push({ name: 'home' });
        }
      });
    },
    doSubmit() {
      const loginPath = '/WebLogin.php';
      const data = new FormData();
      data.append('login', this.model.email);
      data.append('password', this.model.password);
      fetch(server + loginPath, {
        method: 'POST',
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          this.loginError = true;
          destroyToken();
        }
        if (json.status === 'ok') {
          this.loginError = false;
          saveToken(json.token);
          window.localStorage.setItem('role', json.type);
          this.$router.push({ name: 'home' });
        }
      });
    },
  },
  beforeMount() {
  },
};
</script>
<style>
.login-container {
  height: 100%;
  margin: auto;

  .auth-forgot-password a {
    color: #1694D0 !important;
  }
  .control{
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: absolute;
    text-align: inherit;
    right: 16px;
    top: 20px;
    .button{
      display: flex;
    }
  }
}

.create-account{
  margin-top: 12px
}
.btn.btn-light.login-with-google{
  border: 0!important;
  color: #505161!important;
  font-size: 14px!important;
  display: flex;
  justify-content: center;
  font-weight: normal!important;
  img{
    width: 22px;
    height: 22px;
  }
}

.login-container{
@media (min-width: 768px) {
  .col-md-6{
    padding: 0 40px;
  }
  .first-col {
    border-right: 1px solid #E0E0E0;
  }
  .create-account{
    margin-top: 112px
  }

}}
.login-error-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #D66A5B;
  margin-bottom: 4px;
}
.form-control.login-error {
  border: 1px solid #D66A5B!important;
  box-shadow: 2px 2px 4px rgba(166, 171, 189, 0.25);
}
</style>
